@import "../../global.scss";

$searchDims: 60px;

.site_wrapper {
    display: grid;
    grid-template-columns: 1fr $width1 1fr;
    grid-template-rows: auto 1fr;
    min-height: 100vh;
    max-width: 100vw;

    @include smallDesktop {
        grid-template-columns: 1fr $width2 1fr;
    }

    @include mobile {
        grid-template-columns: 1fr $width3 1fr;
        height: auto;
    }

    @include mobile2 {
        grid-template-columns: 1fr auto 1fr;
        height: auto;
    }

    .background {
        grid-row: 1/2;
        grid-column: 1/-1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2; /* Ensure the background stays behind the content */
        img {
            width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .background_filter {
        grid-row: 1/2;
        grid-column: 1/-1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1; /* Ensure the background stays behind the content */
        background-color: rgba(22, 23, 44, 0.438);
    }

    .margin_left {
        grid-column: 1/2;
        grid-row: 1/-1;
    }

    .margin_right {
        grid-column: 3/4;
        grid-row: 1/-1;
    }

    .site_margin {
        display: block;
        margin: 7%;
        z-index: 1;
        // background-color: white;

        @include mobile2 {
            width: 0px;
            margin: 0%;
        }
    }

    header {
        grid-row: 1/2;
        grid-column: 2/3;
        height: 100px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 1%;

        @include mobile2 {
            grid-column: 1/-1;
        }

        h1 {
            font-family: Roboto;
            color: white;
            font-size: 70px;
            font-weight: 600;
            letter-spacing: 2px;
            text-align: center;

            cursor: pointer;
            margin-bottom: 10px;
        }

        hr {
            position: absolute; /* Set position to absolute */
            left: 50%; /* Move the hr to the center horizontally */
            transform: translateX(-50%); /* Center the hr horizontally */
            border: 1px solid white; /* Define the style of the line */
            width: 250px; /* Adjust the width of the hr */
        }
    }

    .site_content {
        grid-row:2/3;
        grid-column: 2/3;

        width: 100%;

        @include mobile2 {
            grid-column: 1/-1;
        }

        .search_wrapper {
            //background-color: grey;

            .gamefinished {
                text-align: center;
                color: white;
                font-size: 36px;
                font-family: Roboto;
            }

            .search_content {
                display: grid;
                grid-template-rows: 1fr;

                .search_header {
                    display: block;
                    text-align: center;

                    h2 {
                        font-family: Roboto;
                        color: white;
                        font-size: 36px;
                    }

                    input {
                        width: 200px;
                        height: 50px;
                        padding-left: 7px;
                        font-size: 16px;
                        font-family: Roboto;
                        margin-top: 5px;
                        border: 2px solid black;
                        border-radius: 5px;
                        transition: background-color 0.2s ease-in-out;
                    }

                    input:hover {
                        background-color: rgb(240, 240, 240);
                    }
                }

                .search_results {
                    display: flex;
                    justify-content: center;
                    justify-self: center;
                    width: 70%;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    .myDropDown
                    {
                        width: 70%;
                        max-height: 220px;
                        overflow: auto;
                        border-top: 2px solid black;
                        border-left: 2px solid black;
                        border-right: 2px solid black;
                        border-bottom: 2px solid black;

                        li {
                            height: $searchDims;
                            border-bottom: 2px solid black;
                            cursor: pointer;
                            display: grid;
                            grid-template-columns: $searchDims 1fr;

                            background-color: rgba(37, 39, 39, 0.233);
                            transition: background-color 0.1s ease-in-out;

                            .search_thumb {
                                display: flex;
                                height: $searchDims;
                                width: $searchDims;
                                border-right: 2px solid black;
                                border-bottom: 2px solid black;
                                background-color: rgb(173, 216, 230);

                                img {
                                    width: 100%;
                                    max-height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .search_name {
                                display: flex;
                                text-align: center;
                                justify-content: center;
                                align-items: center;
                                font-family: Roboto;
                                color: white;
                                font-weight: 500;
                                font-size: 20px;
                                background-color: rgba(61, 61, 61, 0.452);
                                -webkit-text-stroke-width: 1px;
                                -webkit-text-stroke-color: rgba(0, 0, 0, 0.055);
                            }
                        }

                        li:hover {
                            background-color: rgba(173, 216, 230, 0.685);
                        }
                    }
                }
            }
        }

        .guessed_characters {
            display: grid;
            justify-content: center;
            grid-template-rows: auto;
            text-align: center;
            margin-top: 30px;

            .trait_header {
                display: grid;
                grid-template-columns: $guesstraitgrid;
                justify-self: center;
                width: $guesstraitwidth * $guesstraitamount;
                height: 30px;

                @include mobile {
                    width: $guesstraitwidth2 * $guesstraitamount;
                }

                div {
                    display: flex;
                    justify-self: center;
                    justify-content: center;
                    align-items: center;
                    height:30px;
                    width: 100%;
                    border-left: 4px black solid;
                    border-top: 4px black solid;
                    //border-bottom: 4px black solid;
                    background-color: rgba(61, 61, 61, 0.452);
                    font-family: Roboto;
                    color: white;
                    font-weight: 400;
                    letter-spacing: 0.6px;
                    font-size: 16px;

                    @include mobile {
                        font-size: 12px;
                    }
                
                    @include mobile2 {
                        font-size: 12px;
                    }
                }

                .borderright {
                    border-right: 4px black solid;
                }
            }
        }

        .reset_button {
            display: flex;
            justify-content: center;
        }
    }
}

