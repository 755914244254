@import "../../global.scss";

.guess_container {
    display: grid;
    grid-template-columns: $guesstraitgrid;
    justify-self: center;
    width: auto;
    height: auto;
    margin-bottom: 10px;

    @include mobile {
        //width: 90%;
        //height: 90%;
    }

    @include mobile2 {
        //width: 90%;
        //height: 90%;
    }

    .guess_trait {
        display: flex;
        justify-self: center;
        justify-content: center;
        align-items: center;
        height:$guesstraitheight;
        width: $guesstraitwidth;
        border-left: 4px black solid;
        border-top: 4px black solid;
        border-bottom: 4px black solid;
        background-color: lightblue;
        font-family: Roboto;
        color: white;
        font-weight: 400;
        letter-spacing: 0.6px;

        @include mobile {
            width: $guesstraitwidth2;
            height:$guesstraitheight2;
            font-size: 12px;
        }
    
        @include mobile2 {
            font-size: 12px;
        }
        img {
            
            width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .guess_correct {
        background-color: green;
    }

    .guess_wrong {
        background-color: rgb(223, 47, 47);
    }

    .guess_partial {
        background-color: rgb(226, 130, 40);
    }

    .borderright {
        border-right:4px black solid;
    }
}